import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import toast from "react-hot-toast"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AddToCart from "../components/addToCart"
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const ProductInfoStyles = styled.div`
  display: flex;
  justify-content: center;
  padding: 3em 1em;

  [data-rmiz-wrap='visible'],
  [data-rmiz-wrap='hidden'] {
    width: 100%;
    height: 100%;
    display: block;
  }

  .img-wrapper {
    display: flex;
    flex-direction: column;
    padding: 5px;
    background: white;
    border-radius: 6px;

    .product-img {
      height: 100%;
      width: 500px;
      margin: 0 auto;

      img {
        object-fit: contain!important;
      }
    }

    .additional-images {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 1em;

      .gatsby-image-wrapper {
        height: 50px;
        width: 50px;
        padding: 1px;
        border-radius: 6px;
        border: 1px solid lightgray;
        margin: 0 2px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .details {
    width: 700px;
    max-width: 100%;
    font-family: "prompt",Arial,Helvetica,sans-serif;
    padding-left: 2em;

    .form-field {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.5rem;

      select {
        box-sizing: border-box;

        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;

        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.3;
        font-family: "prompt",Arial,Helvetica,sans-serif;
        cursor: default;
        max-width: 500px;
        padding: 10px;
        border: 1px solid #999;
        border-radius: 5px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='292.4' height='292.4'%3E%3Cpath fill='%23333' d='M287 69.4a17.6 17.6 0 0 0-13-5.4H18.4c-5 0-9.3 1.8-12.9 5.4A17.6 17.6 0 0 0 0 82.2c0 5 1.8 9.3 5.4 12.9l128 127.9c3.6 3.6 7.8 5.4 12.8 5.4s9.2-1.8 12.8-5.4L287 95c3.5-3.5 5.4-7.8 5.4-12.8 0-5-1.9-9.2-5.5-12.8z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right 8px center;
        background-size: 9px;
        color: #333;

        &:hover {
          border-color: #777;
        }

        &:focus {
          border-color: #999;
          box-shadow: 0 0 1px 2px #6db4ff;
          outline: none;
        }
      }

      .notes {
        width: 71%;
        padding: 5px;
        font-family: "prompt",Arial,Helvetica,sans-serif;
        border-radius: 5px;
      }
    }

    .description-content {
      padding: 1em 0;
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
      margin-bottom: 2em;
    }

    .out-of-stock {
      width: 100%;
      color: #899499;
      font-weight: 700;
      font-size: 1.5em;
      margin-top: 1em;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;

    .img-wrapper {
      .product-img {
        max-width: 80%;
      }
    }

    .details {
      padding-left: 0;

      .form-field {
        select {
          max-width: 100%;
        }

        .notes {
          width: 98%;
        }
      }
    }
  }
`

const DescriptionStyles = styled.div`
  padding: 1em 5em;
  font-family: "prompt",Arial,Helvetica,sans-serif;
  max-width: 1000px;
  margin: 0 auto;

  .title {
    margin-bottom: 10px;
  }

  .description-content {
    margin-bottom: 40px;
  }

  .description-title {
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #000;
    font-family: "sf-intellivised",Arial,Helvetica,sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    font-style: italic;
  }

  .hand-built {
    font-size: 1.5em;
    font-weight: 700;
    padding-bottom: 0.5em;
  }

  h2 {
    margin: 40px auto;
    text-align: center;
  }

  .color-chart {
    width: 500px;
    height: 500px;
  }

  @media (max-width: 800px) {
    padding: 1em;
  }
`

const ProductDetailsTemplate = ({ data }) => {
  const { shopifyProduct: product } = data
  const {
    variants: [firstVariant],
  } = product
  const [activeVariant, setActiveVariant] = useState({})
  const [activeImage, setActiveImage] = useState(undefined)
  const [images, setImages] = useState([])
  const [options, setOptions] = useState({})
  const [tracksInventory, setTracksInventory] = useState(false)
  const [isSulkyWheel, setIsSulkyWheel] = useState(false)
  const [note, setNote] = useState("")

  useEffect(() => {
    setIsSulkyWheel(product.id === "374258d5-1b5f-5206-b7e4-1a90d5269a87")
    setTracksInventory(data.shopifyProduct.tracksInventory || false)
    const inStockVariant = data.shopifyProduct.variants.find(v => v.inventoryQuantity > 0)
    const newVariant = inStockVariant ? inStockVariant : firstVariant
    setActiveVariant(newVariant)
    if (newVariant.image) {
      setActiveImage(newVariant.image)
    } else if ((data.shopifyProduct.media || []).length > 0) {
      setActiveImage(data.shopifyProduct.media[0]?.preview?.image)
    }

    updateImages(newVariant)
    
    setOptions(newVariant.selectedOptions.reduce((o, selected) => ({ ...o, [selected.name]: selected.value }), {}))
  }, [])

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: "USD",
  })

  const updateVariant = (e) => {
    const newOptions = {
      ...options,
      [e.target.name]: e.target.value
    }
    
    const title = Object.values(newOptions).join(" / ")
    const newVariant = product.variants.find(v => v.title === title)

    if (newVariant) {
      setOptions(newOptions)
      setActiveVariant(newVariant)
      if (newVariant.image) {
        setActiveImage(newVariant.image)
      }
      updateImages(newVariant)
    } else {
      toast.error("Selected options are not available")
    }
  }

  const updateImages = (newVariant) => {
    let newImages = []
    if (newVariant.image) {
      newImages.push(newVariant.image)
    } else if (product.media?.length > 0) {
      newImages = newImages.concat(product.media)
    }
    
    const activeHub =  (newVariant.selectedOptions.find(o => o.name.indexOf("Hub") >= 0) || {}).value
    const hubImage = data.shopifyProduct.media.find(i => i?.preview?.altText === activeHub)
    if (hubImage) {
      newImages.push(hubImage)
    }
    if (newImages.length > 0) {
      setImages(newImages)
    }
  }

  const updateActiveImage = (image) => {
    setActiveImage(image.preview.image)
  }

  const filterOptions = (options) => {
    if (Array.isArray(options)) {
      return options.filter(option => {
        return option.name !== "Title" && (option.values.length > 0 && option.values[0] !== "Default Title")
      }); 
    } else {
      return [];
    }
  }

  return (
    <Layout>
      <SEO
        title={product.title}
        description={product.description}
      />
      <ProductInfoStyles>
        <div className="img-wrapper">
          {activeImage && (
            <GatsbyImage 
              image={activeImage.gatsbyImageData}
              alt={product.title}
              className="product-img" />
          )}
          <div className="additional-images">
            {images.length > 1 && images.map((img, index) => {
              return (
                <div key={index} onClick={(e) => updateActiveImage(img)} >
                  <GatsbyImage 
                    image={img.preview.image.gatsbyImageData}
                    alt={`${product.title} - ${index}`} />
                </div>
              )
            })}
          </div>
        </div>
        <div className="details">
          <h1 className="title">{product.title}</h1>
          <h2>{formatter.format(activeVariant.price)}</h2>
          <p>{product.description}</p>
          {filterOptions(product.options || []).length > 0 && product.options.map((select, index) => {
            return (
              <div key={index} className="form-field">
                <label htmlFor={select.name}>{select.name}</label>
                <select name={select.name} value={options[select.name]} onChange={e => updateVariant(e)}>
                  {select.values.map((option, index) => <option key={index} value={option}>{option}</option>)}
                </select>
              </div>
            )
          })}
          {isSulkyWheel && (
            <div className="form-field">
              <label htmlFor="product-notes">Add a Note:</label>
              <textarea 
                name="product-notes"
                className="notes"
                rows="2"
                maxLength="200"
                value={note}
                onChange={e => setNote(e.target.value)}></textarea>
            </div>
          )}
          {(activeVariant.inventoryQuantity > 0 || tracksInventory === false) && <AddToCart variantId={activeVariant.shopifyId} note={note} />}
          {(activeVariant.inventoryQuantity <= 0 && tracksInventory) && <p className="out-of-stock">Out of Stock</p>}
        </div>
      </ProductInfoStyles>
      <DescriptionStyles>
        <p className="description-title">DESCRIPTION</p>
        <div className="description-content" dangerouslySetInnerHTML={{__html: product.descriptionHtml}}></div>
        {isSulkyWheel && (
          <Zoom>
            <img 
              src={`${data.colorChart.publicURL}`}
              alt="Sulky Wheel Graphics Color Chart"
              className="color-chart"
              style={{
                height: "248px",
                width: "332px"
              }}
            />
          </Zoom>
        )}
        <h2 className="hand-built">FMT Wheels Hand Built in the USA.</h2>
      </DescriptionStyles>
    </Layout>
  );
}

export default ProductDetailsTemplate;

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      handle
      title
      productType
      description
      descriptionHtml
      tracksInventory
      variants {
        id
        title
        price
        sku
        availableForSale
        shopifyId
        inventoryQuantity
        selectedOptions {
          name
          value
        }
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      media {
        preview {
          image {
            altText
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      options {
        position
        name
        values
      }
    }
    colorChart: file(sourceInstanceName: { eq: "assets" }, relativePath: { eq: "fmt-color-chart.jpg" }) {
      absolutePath
      publicURL
    }
  }
`